var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "선택 공정의 KRAS 이력",
          columns: _vm.grid1.columns,
          data: _vm.grid1.data,
          gridHeight: "350px",
          filtering: false,
          columnSetting: false,
          isFullScreen: false,
          usePaging: false,
          expandAll: true,
          "hide-bottom": true,
        },
        on: { rowClick: _vm.rowClick },
      }),
      _c("c-table", {
        ref: "processTable",
        attrs: {
          title: "선택 평가의 시나리오 목록",
          columns: _vm.grid2.columns,
          data: _vm.grid2.data,
          gridHeight: "350px",
          merge: _vm.grid2.merge,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: false,
          "hide-bottom": true,
        },
      }),
      _c("c-table", {
        ref: "processTable",
        attrs: {
          title: "선택 평가의 Punch-list",
          columns: _vm.grid3.columns,
          data: _vm.grid3.data,
          merge: _vm.grid3.merge,
          gridHeight: "350px",
          filtering: false,
          columnSetting: false,
          isFullScreen: false,
          usePaging: false,
          expandAll: true,
          "hide-bottom": true,
        },
        on: { linkClick: _vm.linkClick },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }